.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: Expo;
  src: url("./Assets/Fonts/UniSIRWAN\ Expo\ Regular.ttf") format("truetype");
}

.Expo {
  font-family: Expo;
}

@font-face {
  font-family: ExpoMedium;
  src: url("./Assets/Fonts/UniSIRWAN\ Expo\ Medium.ttf") format("truetype");
}
.ExpoM {
  font-family: ExpoMedium;
}

@font-face {
  font-family: ExpoBold;
  src: url("./Assets/Fonts/UniSIRWAN\ Expo\ Bold.ttf") format("truetype");
}
.ExpoB {
  font-family: ExpoBold;
}





.block-container {
  position: relative;
  width: 120px;
  height: 120px;
  margin:12px 32px;
  transition: 250ms;
  perspective: 500px;
  cursor:pointer
}
.depart{
  margin: 32px 0;

}

.btn-back {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: inherit;
  height: inherit;
  border-radius: 32px;
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: bottom right;
  will-change: transform;

}

.block-container:hover> .btn-back {
transform: translateZ(20px) rotateZ(15deg) rotateX(-20deg) rotateY(-20deg);

}

      




.btn-back-1 {
  background: linear-gradient(135deg, #4CAF50 -40%, #2196F3 120%);

}

.btn-back-2 {
  background: linear-gradient(135deg, #87CEEB -20%, #23CBEB 120%);
}

.btn-back-3 {
  background: linear-gradient(135deg, #001F3F -20%, #800000 120%);
}


.btn-back-4 {
  background: linear-gradient(135deg, #008080 -20%, #C0C0C0 120%);
}
.btn-back-5 {
  background: linear-gradient(135deg, #FF0000 -20%, #00FF00 120%);
}
.btn-back-6 {
  background: linear-gradient(135deg, #D2B48C -20%, #8B4513 120%);
}
.btn-back-7 {
  background: linear-gradient(135deg, #00FF00 -20%, #87CEEB 120%);
}
.btn-back-8 {
  background: linear-gradient(135deg, #808080 -20%, #2F4F4F 120%);
}
.btn-back-9 {
  background: linear-gradient(135deg, #FFA500 -20%, #FFFF00 120%);
}

.btn-front {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
  border-radius: 32px;
  background-color: #ffffff33;
  backdrop-filter: blur(20px);
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: top left;
  overflow: hidden;
}

.btn-front svg.frame {
  width: inherit;
  height: inherit;
}

.btn-front svg.frame rect {
  width: inherit;
  height: inherit;
  fill: none;
  stroke-width: 4;
}

.btn-front svg.icon {
  position: absolute;
  inset: 50% 0% 0 50%;
  transform: translate(-50%, -50%);
  width:50%;
}

